<template>
	<el-dialog title="此操作将通过 OA 获取最新考勤" width="360px" :visible.sync="config_dialog" append-to-body destroy-on-close>
		<el-form label-width="0" ref="updcheck_form" :model="updcheck" :rules="rules" :size="theme.size" status-icon>
			<el-form-item prop="month">
				<el-date-picker type="month" value-format="yyyy-MM" placeholder="选择考核月份" v-model="updcheck.month" :picker-options="pickerOptions" :editable="false" :clearable="false"></el-date-picker>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="submitForm('updcheck_form')">确认获取</el-button>
			</el-form-item>
			<el-form-item>
				<p class="c9 fs12 lh1_5">操作前请先通知完成考勤异常处理<br/>无法获取更新 OA [已离职｜不参考考勤]人员打卡记录<br/>请人事在离职人员确认考勤后再在 OA 移除<br/>已签名确认不会更新</p>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		computed: {
			...mapState(['theme'])
		},
		methods: {
			open () {
				this.config_dialog = true;
			},
			async submitCheckin (data) {
				const res = await this.$http.post(this.$api.URI_CHECKIN, data);
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message.success({
					message,
					onClose: () => {
						this.$emit('refresh');
						this.updcheck = {};
						this.config_dialog = false;
					}
				});
			},
			submitForm (ref) {
				this.$refs[ref].validate(async (valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					switch (ref) {
						case 'updcheck_form':
							this.submitCheckin(this.updcheck);
						break;
					}
				});
			}
		},
		data() {
			return {
				config_dialog: false,
				updcheck: {},
				rules: {
					month: [{ required: true, message: '请选择考核月份', trigger: 'blur' }]
				},
				pickerOptions: {
					disabledDate (time) {
						// 获取当前时间
						const now = new Date();
						// 获取当前年份和月份
						const currentYear = now.getFullYear();
						// 月份是从0开始的，所以要加1
						const currentMonth = now.getMonth() + 1;
						// 获取选择日期的年份和月份
						const selectYear = time.getFullYear();
						// 月份是从0开始的，所以要加1
						const selectMonth = time.getMonth() + 1;
						// 如果选择的年份大于当前年份，或者年份相同但月份大于当前月份，则禁用该日期
						return selectYear > currentYear || (selectYear === currentYear && selectMonth > currentMonth);
					}
				}
			}
		}
	};
</script>