<template>
	<main-table-a>
		<div slot="table" class="f1 df fdc">
			<el-form :inline="true" :model="params" :size="theme.size">
				<el-form-item>
					<el-date-picker type="month" value-format="yyyy-MM" placeholder="选择考核月份" v-model="params.month" :picker-options="pickerOptions" :editable="false" clearable></el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-cascader style="width: 100%;" placeholder="请选择所属部门" v-model="params.departments" :options="tree_departments" :props="{expandTrigger: 'hover', value: 'unique_id', label: 'name', checkStrictly: true}" filterable clearable></el-cascader>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.employee_id" placeholder="请选择员工" filterable multiple collapse-tags>
						<el-option v-for="employee in employees" :key="employee.id" :label="employee.name+(employee.separated_at ? '(已离职)' : '')" :value="employee.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getCheckin({ ...params, page: 1})" plain>查询</el-button>
					<el-button type="primary" @click="checkings_dialog = true;" v-if="$utils.create($api.URI_CHECKIN)">添加考勤</el-button>
					<i class="el-icon-setting cccc ml10 cp" @click="$refs['config'].open()"></i>
				</el-form-item>
			</el-form>
			<div class="f1 pr">
				<el-table ref="checking_in_table" class="checking_in scroll-wrapper absolute" height="100%" :data="checkings" :size="theme.size" :row-class-name="tableRowClassName">
					<el-table-column label="员工" min-width="90">
						<template slot-scope="scope">
							<img class="db" :src="scope.row.signatured" v-if="scope.row.signatured">
							<sapn v-else>{{scope.row.employee.name}}</sapn>
						</template>
					</el-table-column>
					<el-table-column label="考核月份" prop="period_at" min-width="90">
						<template slot-scope="scope">
							<el-tooltip effect="dark">
								<div slot="content" class="lh1_5">
									<!-- <template v-if="theme.width <= 1940">
										<div class="tac">打卡记录(天数)</div>
										<div class="df mb10 jcsb">
											<div class="tac">
												<p>{{scope.row.summary_info.work_days || 0}}</p>
												<p>标准</p>
											</div>
											<div class="tac">
												<p>{{scope.row.summary_info.rest_days || 0}}</p>
												<p>休息</p>
											</div>
											<div class="tac">
												<p>{{scope.row.summary_info.regular_days || 0}}</p>
												<p>正常</p>
											</div>
											<div class="tac">
												<p>{{scope.row.summary_info.except_days || 0}}</p>
												<p>异常</p>
											</div>
											<div class="tac">
												<p>{{scope.row.exception_count_3 || 0}}</p>
												<p>缺卡</p>
											</div>
										</div>
									</template> -->
									<p>创建于 {{scope.row.created_at}}</p>
									<p>更新于 {{scope.row.updated_at}}</p>
								</div>
								<span class="fwb fs14" v-if="scope.row.signatured">{{scope.row.period_at}}</span>
								<el-link type="primary" :disabled="!$utils.update($api.URI_CHECKIN)" @click="checking = {vacations: [], ...scope.row}; checkings_dialog = true;" v-else>{{scope.row.period_at}}</el-link>
							</el-tooltip>
						</template>
					</el-table-column>
					<el-table-column align="center" label="工作日(天数)">
						<el-table-column label="应出勤" prop="work_days" min-width="60"></el-table-column>
						<el-table-column label="实出勤" prop="actual_days" min-width="60"></el-table-column>
					</el-table-column>
					<el-table-column align="center" label="加班时长(小时)">
						<el-table-column label="工作日" prop="workday_over_sec" min-width="60" :formatter="({workday_over_sec}) => { return +workday_over_sec; }"></el-table-column>
						<el-table-column label="休息日" prop="restdays_over_sec" min-width="60" :formatter="({restdays_over_sec}) => { return +restdays_over_sec; }"></el-table-column>
						<el-table-column label="节假日" prop="holidays_over_sec" min-width="60" :formatter="({holidays_over_sec}) => { return +holidays_over_sec; }"></el-table-column>
					</el-table-column>
					<!-- <el-table-column align="center" label="打卡天数">
						<el-table-column label="标准" prop="summary_info.work_days" min-width="46" :formatter="({summary_info}) => { return summary_info ? summary_info.work_days : 0; }"></el-table-column>
						<el-table-column label="正常" prop="summary_info.regular_days" min-width="46" :formatter="({summary_info}) => { return summary_info ? summary_info.regular_days : 0; }"></el-table-column>
						<el-table-column label="休息" prop="summary_info.rest_days" min-width="46" :formatter="({summary_info}) => { return summary_info ? summary_info.rest_days : 0; }"></el-table-column>
						<el-table-column label="异常" prop="summary_info.except_days" min-width="46" :formatter="({summary_info}) => { return summary_info ? summary_info.except_days : 0; }"></el-table-column>
						<el-table-column label="缺卡" prop="exception_count_3" min-width="46" :formatter="({exception_count_3}) => { return exception_count_3 || 0; }"></el-table-column>
					</el-table-column> -->
					<el-table-column align="center" v-for="(exception, e) in exceptions" :key="'exception_'+e" :label="exception">
						<el-table-column label="次数" min-width="46" :formatter="(r) => { return (r['exception_count_'+e] || 0); }"></el-table-column>
						<el-table-column label="分钟" min-width="46" :formatter="(r) => { return (r['exception_duration_'+e] || 0)/60; }"></el-table-column>
					</el-table-column>
					<el-table-column align="center" label="出差">
						<el-table-column label="次数" min-width="46" :formatter="({sp_count_3}) => { return sp_count_3 || 0; }"></el-table-column>
						<el-table-column label="小时" min-width="58" :formatter="({sp_duration_3}) => { return +(sp_duration_3 || 0).toFixed(2); }"></el-table-column>
					</el-table-column>
					<el-table-column align="center" label="假勤">
						<el-table-column label="节假日" prop="holidays" min-width="60" :formatter="({holidays}) => { return +holidays; }"></el-table-column>
						<el-table-column :min-width="vacation.time_attr ? 92 : 80" v-for="vacation in vacations" :key="'vacation_'+vacation.id" :label="vacation.name" :formatter="(r) => { return (r['vacation_'+vacation.id] || 0); }">
							<template slot="header">{{vacation.name}}({{vacation.time_attr ? '小时' : '天'}})</template>
						</el-table-column>
					</el-table-column>
					<!-- <el-table-column label="更新时间" prop="updated_at" min-width="152">
						<template slot="header">更新时间 <el-tooltip effect="dark" content="更新来源于创建后次月1~5号自动获取OA上月最新考勤数据。当企业允许次月处理上月打卡异常有效，OA 无法获取更新已离职人员打卡记录，请人事在离职人员次月确认考勤后再在 OA 移除"><i class="el-icon-info ch"></i></el-tooltip></template>
					</el-table-column>
					<el-table-column label="创建时间" prop="created_at" min-width="152">
						<template slot="header">创建时间 <el-tooltip effect="dark" content="创建来源于每月最后一天自动获取OA当月考勤数据"><i class="el-icon-info ch"></i></el-tooltip></template>
					</el-table-column> -->
				</el-table>
			</div>
		</div>
		<el-pagination
			slot="footer"
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => { getCheckin({ ...params, page: 1, perPage: v }) }"
			@current-change="(v) => { getCheckin({ ...params, page: v }) }"
			background>	
		</el-pagination>
		<template slot="other">
			<rules ref="config"></rules>
			<create ref="create_check" @refresh="getCheckin({ ...params, page: 1})"></create>
			<el-dialog :title="checking.id ? '编辑考勤' : '添加考勤'" width="320px" :visible.sync="checkings_dialog" @closed="checking = { vacations: [] }" append-to-body destroy-on-close>
				<div>
					<el-form label-width="90px" ref="checking_form" :model="checking" :rules="checking_rules" :size="theme.size" status-icon>
						<el-form-item label="员工" v-if="checking.employee">{{checking.employee.name}}</el-form-item>
						<template v-if="!checking.id">
							<el-form-item label="考勤月份" prop="month">
								<el-date-picker style="width: 100%;" type="month" value-format="yyyy-MM" placeholder="选择考核月份" v-model="checking.month" :picker-options="pickerOptions" :editable="false" :clearable="false"></el-date-picker>
							</el-form-item>
							<el-form-item label="指定员工" prop="employee_id">
								<el-select v-model="checking.employee_id" placeholder="请选择员工" filterable clearable>
									<el-option v-for="employee in employees" :key="employee.id" :label="employee.name+(employee.separated_at ? '(已离职)' : '')" :value="employee.id"></el-option>
								</el-select>
							</el-form-item>
						</template>
						<template v-if="checking.employee_id">
							<el-form-item label="工作日实勤" prop="actual_days">
								<el-input-number placeholder="工作日实勤" v-model="checking.actual_days" :min="0" :max="checking.work_days || 31"></el-input-number> 天
							</el-form-item>
							<el-form-item label="工作日加班" prop="workday_over_sec">
								<el-input-number placeholder="工作日加班" v-model="checking.workday_over_sec" :min="0" :step="0.01"></el-input-number> 小时
							</el-form-item>
							<el-form-item label="休息日加班" prop="restdays_over_sec">
								<el-input-number placeholder="休息日加班" v-model="checking.restdays_over_sec" :min="0" :step="0.01"></el-input-number> 小时
							</el-form-item>
							<el-form-item label="节假日加班" prop="holidays_over_sec">
								<el-input-number placeholder="节假日加班" v-model="checking.holidays_over_sec" :min="0" :step="0.01"></el-input-number> 小时
							</el-form-item>
							<el-form-item label="节假日" prop="holidays">
								<el-input-number placeholder="节假日" v-model="checking.holidays" :min="0"></el-input-number> 天
							</el-form-item>
							<el-form-item :label="vacation.name" :prop="'vacations.'+v" v-for="(vacation, v) in vacations" :key="vacation.vacation_id">
								<el-input-number :placeholder="vacation.name" v-model="checking.vacations[v]" :min="0"></el-input-number> {{vacation.time_attr ? '小时' : '天'}}
							</el-form-item>
							<el-form-item>
								<el-button slot="label" type="text" @click="downloadTemp">下载模板</el-button>
								<el-button type="primary" @click="submitForm('checking_form')">{{checking.id ? '提交保存'  : '提交创建'}}</el-button>
								<el-upload class="dib ml10 mr10" accept=".xls,.xlsx" :auto-upload="false" :show-file-list="false" :on-change="importInventories" :action="$api.URI_UPLOADS" :disabled="!$utils.create($api.URI_CHECKIN)">
									<el-button type="primary" :disabled="!$utils.create($api.URI_CHECKIN)" plain>批量导入</el-button>
								</el-upload>
							</el-form-item>
						</template>
						<el-form-item v-else>
							<el-button type="primary" @click="submitForm('checking_form')" :loading="loading">从 OA 获取</el-button>
								<el-tooltip effect="dark">
									<div slot="content" class="lh1_5">
										<p>建议从 OA 一键获取所有考勤记录</p>
										<p>当员工不参与 OA 考勤才使用手动创建</p>
									</div>
									<i class="el-icon-setting ml10 cp"></i>
								</el-tooltip>
						</el-form-item>
					</el-form>
				</div>
			</el-dialog>
		</template>
	</main-table-a>
</template>

<style>
	.el-table.checking_in .warning-row {
		background: oldlace;
	}

	.el-table.checking_in .success-row {
		background: #f0f9eb;
	}
</style>

<script>
	import { mapState } from 'vuex';
	import mainTableA from '@/components/main-table-a';
	import create from './create';
	import rules from './rules';
	import XLSX from "xlsx";
	import { ad_export_json_to_excel_multi_sheet } from '@/utils/exportExcel';

	export default {
		components: {
			mainTableA,
			create,
			rules
		},
		computed: {
			...mapState(['theme', 'oa_departments']),
			tree_departments () {
				return this.$utils.buildTree(Object.values(this.oa_departments));
			},
			table_temp () {
				const thead = ['员工姓名', '考核月份', '工作日实出勤', '工作日加班时长(小时)', '休息日加班时长(小时)', '节假日加班时长(小时)', '节假日'];
				const filter = ['employee_name', 'month', 'actual_days', 'workday_over_sec', 'restdays_over_sec', 'holidays_over_sec', 'holidays'];
				for (let vacation of this.vacations) {
					thead.push(vacation.name+'('+(vacation.time_attr ? '小时' : '天')+')');
					filter.push(`vacation_${vacation.vacation_id}`);
				}
				return {
					data: [],
					sheet: '明细列表',
					thead,
					filter
				};
			}
		},
		methods: {
			tableRowClassName({row}) {
				if (row.signatured) return 'success-row';
				return '';
			},
			async submitCheckin (data) {
				this.loading = true;
				const res = await this.$http.post(this.$api.URI_CHECKIN, data);
				const { code, msg: message } = res.data;
				this.loading = false;
				if (code != 0) return this.$message.error(message);
				this.$message.success({
					message,
					onClose: () => {
						this.getCheckin(this.params);
						this.checkings_dialog = false;
					}
				});
			},
			submitForm (ref) {
				this.$refs[ref].validate(async (valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					switch (ref) {
						case 'checking_form':
							if (this.loading) return false;
							var { vacations } = this.checking;
							// delete this.checking.vacations;
							for (let v in vacations) {
								this.checking[`vacation_${this.vacations[v].vacation_id}`] = vacations[v];
							}
							// console.log(this.checking);
							this.submitCheckin(this.checking);
						break;
					}
				});
			},
			async getCheckin (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_CHECKIN, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.checkings = result.data;
				this.employees = result.employees;
				this.vacations = result.vacations;
				this.exceptions = result.exceptions;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			},
			importInventories ({raw}) {
				this.importing = true;
				const fileReader = new FileReader();
				fileReader.readAsBinaryString(raw);
				fileReader.onload = async ({target}) => {
					try {
						const { thead, filter } = this.table_temp;
						const { Sheets, SheetNames } = XLSX.read(target.result, {type: "binary"});
						const data = XLSX.utils.sheet_to_json(Sheets[SheetNames[0]], {header: filter});
						data.shift();
						let index = 0;
						let notify = this.$notify({
							title: '提示',
							dangerouslyUseHTMLString: true,
							message: '<p>正在准备导入...</p>',
							showClose: false,
							duration: 0
						});
						for (var i in data) {
							const { employee_name, month } = data[i];
							index += 1;
							notify.message = '<p>正在导入 ' + index + ' / ' + data.length + '</p>';
							if (!month) {
								data[i].status = "考勤月份不能为空";
								continue;
							}
							if (!this.$moment(month, 'YYYY-MM-DD', true).isValid()) {
								data[i].status = "提交月份非 YYYY-MM-DD 文本格式，修改请在前面加'，请重新下载模版";
								continue;
							}
							if (!employee_name) {
								data[i].status = "提交员工姓名不能为空";
								continue;
							}
							if (!this.employees.find(e => e.name === employee_name)) {
								data[i].status = "提交员工姓名不存在";
								continue;
							}
							const res = await this.$http.post(this.$api.URI_CHECKIN, {...data[i]});
							const { code, msg } = res.data;
							data[i].status = msg;
							if (code != 0) continue;
						}
						if (index === data.length) {
							notify.message = '<p>导入完成！</p>';
							setTimeout(() => {
								this.$utils.handleDownload([[...thead, '状态']], [...filter, 'status'], data, "考勤记录导入结果" + this.$moment().format('YYYYMMDDHHmmss'), "导入明细");
								notify.close();
								this.getCheckin(this.params);
								this.checkings_dialog = false;
							}, 3000);
						}
					} catch (e) {
						console.log(e);
					}
				}
			},
			downloadTemp () {
				const data = [];
				for (let employee of this.employees) {
					data.push({employee_name: employee.name, month: this.$moment().subtract(1, 'months').format('Y-MM-01'), actual_days: '', workday_over_sec: '', restdays_over_sec: '', holidays_over_sec: ''});
				}
				ad_export_json_to_excel_multi_sheet([{ ...this.table_temp, data }], '考勤记录导入模板_生成于' + this.$moment().format('YYYYMMDD'));
			}
		},
		data() {
			return {
				checking: {
					vacations: []
				},
				checkings: [],
				vacations: [],
				employees: [],
				exceptions: [],
				checking_rules: {
					month: [{ required: true, message: '请选择考核月份', trigger: 'blur' }]
				},
				loading: false,
				checkings_dialog: false,
				params: {
					perPage: 10,
				},
				pickerOptions: {
					disabledDate (time) {
						// 获取当前时间
						const now = new Date();
						// 获取当前年份和月份
						const currentYear = now.getFullYear();
						// 月份是从0开始的，所以要加1
						const currentMonth = now.getMonth() + 1;
						// 获取选择日期的年份和月份
						const selectYear = time.getFullYear();
						// 月份是从0开始的，所以要加1
						const selectMonth = time.getMonth() + 1;
						// 如果选择的年份大于当前年份，或者年份相同但月份大于当前月份，则禁用该日期
						return selectYear > currentYear || (selectYear === currentYear && selectMonth > currentMonth);
					}
				}
			}
		},
		created () {
			this.getCheckin(this.params, true);
		}
	};
</script>